import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useEffect, useState } from 'react';
import axios from 'axios';

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import eco from '../assets/Eco.jpg';
import adv from '../assets/adv.jpg';
import med from '../assets/med.jpg';
import heritage from '../assets/heritage.jpg';
import pilgrim from '../assets/pilgrim.jpg';
import honeymoon from '../assets/hmoon.jpg';
import { useNavigate } from 'react-router-dom';

const baseUrl = 'https://admin.tourismooze.com/storage/'; // Base URL for images

const ImageGrid = () => {
  const navigate = useNavigate()
  const [data, setData] = useState([])

  useEffect(() => {

    const fetchData = async () => {
      try {

        const response = await axios.post('https://admin.tourismooze.com/api/public/home');

        if (response.data.data && response.data.data.categories) {
          setData(response.data.data.categories);
          console.log("exploreData", response.data.data.categories);

          response.data.data.categories.forEach((categories, index) => {
            console.log("name:", categories.name);
            console.log("name:", categories.id);
            console.log("imagepath:", baseUrl + categories.image_path);

          });

        }

      } catch {

      }
    }

    fetchData();

  }, [])

  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={2}
      breakpoints={{
        640: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 6,
        },
        1024: {
          slidesPerView: 8,
        },
      }}
      className="p-4"
    >
      {data.map((categories, index) => (
        <SwiperSlide key={index} className="flex flex-col items-center bg-transparent">
          <div className=''>
            <img
              src={baseUrl + categories.image_path}
              alt={categories.name}
              className="h-32 w-32 object-cover rounded-full"
            />
            <div className="bottom-0 my-2 text-center text-md font-semibold text-black rounded px-2">
              {categories.name} 
            </div>
          </div>

        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ImageGrid;
