import React, { useEffect, useState, useContext, useRef } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import avatar from '../assets/avatar.jpg';
import booking from '../assets/booking.svg';
import planning from '../assets/planning.svg';
import about from '../assets/about.svg';
import feedback from '../assets/feedback.svg';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from 'react-router-dom';
import Popup from './Popup';
import { CircleXIcon } from "lucide-react";
import { Link } from "react-router-dom";

function ProfileDrawer({ onClose }) {
  const baseUrl = 'https://admin.tourismooze.com/storage/'; // Replace with your actual base URL
  const navigate = useNavigate()
  const { setProfilePic } = useContext(AuthContext);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const MySwal = withReactContent(Swal);
  const [errors, setErrors] = useState({});
  
  const [profileInfo, setProfileInfo] = useState(null);
  const { token, profilePic } = useContext(AuthContext);
  const { currency } = useContext(AuthContext);
  const fileInputRef = useRef(null);
  const [profileImg, setProfileImg] = useState(false)
  const [editProfile, setEditProfile] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const fetchProfileData = async () => {
    try {
      const response = await axios.post(
        'https://admin.tourismooze.com/api/public/profile',
        {}, // Empty payload if no data to send
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );

      const data = response.data.data;
      const profilepic = response.data.data.profile_pic;
      setProfilePic(profilepic);
      console.log('new profile pic', profilePic)
      setProfileInfo(data);

      console.log('profiledata', data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleNameUpdate = (e) => {
    setName(e.target.value);
  };

  const handleEmailUpdate = (e) => {
    setEmail(e.target.value);
  };
  // update profile function
  const validate = () => {
    const errors = {};
    if (!name) {
      errors.name = 'Name is required';
    }
    if (!email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email format is invalid';
    }
    return errors;
  };
  console.log('ss', currency)


  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validate();

    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await axios.post(
          'https://admin.tourismooze.com/api/public/profile/update',
          {
            name: name,
            email: email
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );

        const data = response.data.data;
        console.log('profiledata', data);

      } catch (error) {
        console.error('Error updating profile:', error);
      }

      console.log('Name:', name);
      console.log('Email:', email);
      setEditProfile(false);
      setEmail('');
      setName('');
      setErrors('');
      fetchProfileData()

    } else {
      console.error('Validation Errors:', validationErrors);
      setErrors(validationErrors);
    }
  };


  // end update profile function




  const capitalizeFirstLetter = (string) => {
    if (!string) return ""; // Return empty string if the input is falsy
    return string.charAt(0).toUpperCase() + string.slice(1);
  };





  //   profilepic update
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('profile_pic', file);

    try {
      const response = await axios.post(
        'https://admin.tourismooze.com/api/public/profile/picture', // Adjust the endpoint as necessary
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      // Update profile info with new profile picture
      setProfileInfo((prevInfo) => ({
        ...prevInfo,
        profile_pic: response.data.profile_pic, // Adjust according to the actual response
      }));
      fetchProfileData();
      setProfileImg(true)
      console.log('Profile picture updated:', response.data);
      if (response.data.status == false) {
        MySwal.fire({
          title: <p className='text-sm font-poppins'>{`${response.data.errors.profile_pic}`}</p>,
          timer: 2000,
        showConfirmButton: false
        })
      }
      MySwal.fire({
        title: <p className='text-lg font-poppins'>{`${response.data.message}`}</p>,
        timer: 2000,
        showConfirmButton: false
      })

    } catch (error) {
      console.error(error);
    }
  };

  const handleAddImageClick = () => {
    fileInputRef.current.click();
  };
  // end profile pic update






  const handleCloseClick = () => {
    setIsPopupVisible(false)
  }


  useEffect(() => {
    fetchProfileData();
  }, []);

  return (
    <div>
      {isPopupVisible && <Popup onCloseClick={handleCloseClick} />}

      {profileInfo ? (
        <div className='fixed top-0 right-0 h-full rounded-l-3xl bg-white shadow-lg sm:p-4 p-3 z-40 w-[80%] md:w-[50%] lg:w-[40%] xl:w-[30%]'>
          <button className="absolute top-4 right-4" onClick={() => onClose()}>
          <CircleXIcon className="text-gray-300 group-hover:text-gray-400" />
          </button>


          {/* Image and desc grid */}
          <div className='flex flex-row items-center mx-auto gap-5 mb-3'>
            <div className='relative flex-shrink-0'>
              {profileInfo.profile_pic ? (
                <img src={baseUrl + profilePic} alt="Profile" className="rounded-full w-[60px] h-[60px] object-cover" />
              ) : (
                <img src={avatar} alt='profile pic' className='size-full rounded-full w-[60px] h-[60px] object-cover' />

              )}
              <div className="absolute inset-0 bg-black w-[60px] h-[60px] bg-opacity-30 rounded-full"></div>
              <span
                className="text-[10px] font-normal font-poppins text-center absolute inset-0 flex items-end justify-center text-white p-2 cursor-pointer"
                onClick={handleAddImageClick}
              >
                Add image
              </span>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
            </div>
            <div className='text-left'>
              <p className='font-poppins font-extrabold text-xl md:text-2xl text-left '>
                Hi, {capitalizeFirstLetter(profileInfo.name)}
              </p>
              <p className='font-poppins text-[12px] md:text-sm text-primary text-left'>Welcome to Tourismooze !</p>
              
            </div>
          </div>
          {/* End image and desc grid */}

          <div className='grid grid-cols-2 justify-between'>
            <div className='font-poppins text-sm md:text-sm'>Basic Details</div>
            <div><button className='font-poppins text-sm border-[#1E75BB] border-[1px] rounded-xl text-[#1E75BB] px-4 float-right' onClick={() => setEditProfile(true)}>Edit</button></div>
          </div>

          {/* Info section */}

          {editProfile == true ? <div className='bg-lightblue h-auto my-1 rounded-md p-2'>
            <div className=''>
              <p className='text-sm text-text font-poppins  my-1'>Name</p>
              <input className='font-poppins text-sm text-[#587893] px-4 py-[1px] rounded-md shadow-sm focus:outline-none' placeholder={profileInfo.name} value={name} onChange={handleNameUpdate} />
              {errors.name && <p className="text-red-500 text-sm font-poppins">{errors.name}</p>}
            </div>
            <div>
              <p className='text-text font-poppins text-sm my-1'>Email ID</p>
              <input className='font-poppins text-sm text-text px-4 py-[1px] rounded-md shadow-sm focus:outline-none' placeholder={profileInfo.email} value={email} onChange={handleEmailUpdate} />
              {errors.email && <p className="text-red-500 text-sm font-poppins">{errors.email}</p>}
            </div>
            <div className='grid grid-cols-2 items-center justify-between w-60 mx-auto px-1 gap-2'>
              <button className='font-poppins border-[#1E75BB] text-sm border-[1px] rounded-xl text-[#1E75BB] px-4 mt-4' onClick={() => setEditProfile(false)}>cancel</button>
              <button className='font-poppins bg-primary  text-sm border-[1px] rounded-xl text-white px-4 mt-4' onClick={handleSubmit}>Update</button>

            </div>

          </div> : <div className='bg-lightblue h-auto my-1 rounded-md p-2'>
            <div className=' mb-6'>
              <p className='text-text font-poppins text-sm my-1'>Phone number</p>
              <p className='font-poppins text-sm'>{profileInfo.phone}</p>
            </div>
            <div>
              <p className='text-text font-poppins text-sm my-1'>Email ID</p>
              <p className='font-poppins text-sm'>{profileInfo.email}</p>
            </div>
          </div>
          }
          {/* End info section */}

          {/* Booking, feedback area */}
          <div className='grid grid-cols-1 p-2 gap-3 mt-3'>

            <div className='flex gap-5 justify-center'>
            <div className='flex items-center gap-2 mb-4 md:mb-3  w-[40%]   border-primary border px-2 py-1 rounded-md hover:bg-lightgray'>
              <img src={booking} alt='icons' className='w-6 h-6' />
              <p className='font-poppins text-sm  cursor-pointer' onClick={() => navigate('/my-booking')}>My Booking</p>
            </div>
            <div className='flex items-center gap-2 mb-4 md:mb-3 w-[40%]  border-secondary border px-2 py-1 rounded-md hover:bg-lightgray'>
              <img src={planning} alt='icons' className='w-6 h-6' />
              <p className='font-poppins text-sm  cursor-pointer' onClick={() => navigate('/my-trip-plans')}>My trip plans</p>
            </div>
            </div>
            <div className='flex items-center gap-5 mb-4 md:mb-3 md:mt-5'>
              <img src={about} alt='icons' className='w-6 h-6' />
              <p className='font-poppins text-sm md:text-base'>About us</p>
            </div>
            <div className='flex items-center gap-5'>
              <img src={feedback} alt='icons' className='w-6 h-6' />
              <p className='font-poppins text-sm md:text-base'>Complaints / Feedbacks</p>
            </div>
          </div>
          {/* End booking feedback area */}

          <div className='mt-3 ml-12'>
            <p className='font-poppins text-sm md:text-base p-2 md:hidden '><Link  to="/destinations">Explore Destinations</Link></p>
            <p className='font-poppins text-sm md:text-base p-2 md:hidden '> <Link  to="/packages">Explore Packages</Link></p>
            <p className='font-poppins text-sm md:text-base p-2'>FAQ</p>
            <p className='font-poppins text-sm md:text-base p-2'>Terms and Conditions</p>
            <p className='font-poppins text-sm md:text-base p-2'>Privacy Policy</p>
          </div>
        </div>
      ) : (
        <div className='fixed top-0 right-0 h-full rounded-l-3xl bg-white shadow-lg sm:p-4 p-3 z-40 w-[80%] md:w-[50%] lg:w-[40%] xl:w-[30%]'>
          <button className="absolute top-4 right-4" onClick={() => onClose()}>
          <CircleXIcon className="text-gray-300 group-hover:text-gray-400" />
          </button>

          {/* Image and desc grid */}
          <div className='flex flex-row items-center mx-auto mt-1 gap-5 mb-12'>
            <div className='relative flex-shrink-0'>
              <img src={avatar} alt='profile pic' className='rounded-full  w-[60px] h-[60px] object-cover' />
              <span
                className="text-[12px] font-normal font-poppins text-center absolute inset-0 flex items-end justify-center text-black p-2"

              >

              </span>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
            </div>
            <div className='text-left'>
              <p className='font-poppins font-extrabold text-xl md:text-2xl text-left'>Hi, Guest</p>
              <p className='font-poppins text-[12px] md:text-sm text-primary text-left'>Please login or signup...</p>
            </div>
          </div>
          {/* End image and desc grid */}


          {/* Booking, feedback area */}
          <div className='grid grid-cols-1 p-2 gap-3 mt-3'>

            <div className='flex items-center gap-5 mb-4 md:mb-2'>
              <img src={about} alt='icons' className='w-6 h-6' />
              <p className='font-poppins text-sm md:text-base'>About us</p>
            </div>
            <div className='flex items-center gap-5'>
              <img src={feedback} alt='icons' className='w-6 h-6' />
              <p className='font-poppins text-sm md:text-base'>Complaints / Feedbacks</p>
            </div>
          </div>
          {/* End booking feedback area */}

          <div className='mt-3 ml-12'>
            
           
            <p className='font-poppins text-sm md:text-base p-2 md:hidden '><Link  to="/destinations">Explore Destinations</Link></p>
            <p className='font-poppins text-sm md:text-base p-2 md:hidden '> <Link  to="/packages">Explore Packages</Link></p>
            <p className='font-poppins text-sm md:text-base p-2'>FAQ</p>
            <p className='font-poppins text-sm md:text-base p-2'>Terms and Conditions</p>
            <p className='font-poppins text-sm md:text-base p-2'>Privacy Policy</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfileDrawer;
