// src/components/CurrencySelector.js
import React from 'react';
import Select, { components } from 'react-select';
import { currencies } from '../data/currencies';
import { Pointer } from 'lucide-react';

// Custom single value component to include flag and label
const SingleValue = ({ data }) => (
  <div className="flex items-center space-x-2 cursor-pointer">
    <img src={data.flag} alt={`${data.label} flag`} className="w-6 h-4" />
    <span className='cursor-pointer'>{data.label}</span>
  </div>
);

// Custom option component to include flag and label
const Option = (props) => (
  <components.Option {...props}>
    <div className="flex items-center space-x-2 cursor-pointer">
      <img src={props.data.flag} alt={`${props.data.label} flag`} className="w-3 h-2" />
      <span className='cursor-pointer'>{props.data.label}</span>
    </div>
  </components.Option>
);

const CurrencySelectorHeader = ({ selectedCurrency, setSelectedCurrency }) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      padding: '0.5rem',
      color: 'darktext',
      backgroundColor: '#ffffff',
      borderRadius: '0.5rem',
      fontFamily:'poppins',
      border: '0px solid #cccccc',
      pointer:'cursor',
    }),
    dropdownIndicator: (provided) => ({
      
      pointer:'cursor',
    }),
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      padding: '0.5rem',
      backgroundColor: state.isSelected ? '#60a5fa' : state.isFocused ? '#dbeafe' : '#ffffff',
      color: state.isSelected ? '#ffffff' : '#000000',
    }),
    singleValue: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      
    }),
  };

  const options = currencies.map((currency) => ({
    value: currency.value,
    label: currency.label,
    flag: currency.flag,
  }));

  return (
    <Select
      value={selectedCurrency}
      onChange={setSelectedCurrency}
      options={options}
      styles={customStyles}
      components={{ SingleValue, Option }} // Use custom SingleValue and Option components
    />
  );
};

export default CurrencySelectorHeader;
