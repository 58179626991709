import { useLocation } from 'react-router-dom';
import bgimage from '../assets/BG.svg';
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';
import axios from 'axios';
import vector from '../assets/Vector.png';

const PlanDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const plan = location.state;
  const [planrequest, setPlanRequest] = useState(null);
  const [destinations, setDestinations] = useState([]);
  const baseUrl = 'https://admin.tourismooze.com/storage/';
  const { token } = useContext(AuthContext);

  useEffect(() => {
    fetchSelectedDestinations();
  }, []);

  const fetchSelectedDestinations = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      const response = await axios.post(`https://admin.tourismooze.com/api/public/enquiry_detail/${plan.id}`, {}, { headers });

      const destinationData = response.data.data.selected_destinations || [];
      const planData = response.data.data.enquiries || null;

      setDestinations(destinationData);
      setPlanRequest(planData);
      
      console.log(response.data.data.enquiries, 'Response Data');
      
    } catch (error) {
      console.error("Error fetching selected destinations:", error);
    }
  };

  const handleRemove = async (index, id) => {
    try {
      const response = await axios.post(
        `https://admin.tourismooze.com/api/public/selected/remove/${id}`,
        {
          payload: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.data.status === true) {
        fetchSelectedDestinations();
      }
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  };

  return (
    <div  className='font-poppins min-h-screen h-screen' 
    style={{ 
      backgroundImage: `url(${bgimage})`, 
      backgroundSize: 'cover', 
      backgroundRepeat: 'no-repeat', 
      backgroundPosition: 'center'
    }}>
      <div className='w-full lg:w-[70%] mx-auto'>
      <button
            onClick={() => navigate(-1)}
            className="flex items-center my-2 focus:outline-none bg-tertiary rounded-md text-white px-2 py-2"
          >
            <IoIosArrowBack className="mr-1 text-white" size={18} />
            Go Back
          </button>
      </div>
 
      <div  className='font-poppins flex justify-center items-center' 
        style={{ 
          backgroundImage: `url(${bgimage})`, 
          backgroundSize: 'cover', 
          backgroundRepeat: 'no-repeat', 
          backgroundPosition: 'center'
        }}>

          


        <div className='bg-white rounded-xl shadow-md w-full m-2 lg:w-[70%] p-6 lg:p-10'>
          <p className='pb-5 text-[#11A59B] font-semibold text-xl'>Enquiry ID: {plan.enquiryid}</p>
          <div className='bg-lightgray p-4 md:p-6 rounded-xl'>
            <div className='flex flex-wrap md:flex-nowrap justify-between'>
              
              <div className='w-full md:w-[50%]'>
                <h1 className='text-[#02AFA3] text-xl font-semibold pb-3'>Trip Plan Request Detail</h1>

                {planrequest ? (
                  <div className='w-full'>
                    <p className='py-2'>ID: <b>{planrequest.id}</b></p>
                    <p className='py-2'>Enquiry ID: <b>{planrequest.enquiryid}</b></p>
                    <p className='py-2'>From Date: <b>{planrequest.selected_date}</b></p>
                    <p className='py-2'>Country Name: <b>{planrequest.countryname}</b></p>
                    <p className='py-2'>Duration: <b>{planrequest.duration}</b></p>
                    <p className='py-2'>Total Persons: <b>Adults: {planrequest.adults}, Children: {planrequest.children}</b></p>
                    <p className='py-2'>Rooms: <b>{planrequest.rooms}</b></p>
                    <p className='py-2'>Selected Destinations Count: <b>{planrequest.selected_destinations_count}</b></p>
                  </div>
                ) : (
                  <p>No plan request details available.</p>
                )}
              </div>

              {/* Selected Destinations */}
              <div className='w-full md:w-[50%] lg:w-[50%] float-right mt-6 md:mt-0'>
                <p className='text-[#1E75BB] text-xl font-semibold pb-3'>Selected Destinations</p>
                <div>
                  {destinations.length > 0 ? (
                    destinations.map((destination, index) => (
                      <div key={index} className='bg-white rounded-md border-[1px] border-[#11A59B] p-4 m-2 shadow'>
                        <div className='flex flex-wrap md:flex-nowrap gap-4 relative'>
                          <img 
                            src={baseUrl + destination.destination_image} 
                            alt={destination.destination_name} 
                            className='w-36 object-cover mt-2 rounded-md' 
                          />
                          <div>
                            <p className='font-poppins font-semibold text-2xl text-[#006464]'>{destination.destination_name}</p>
                            <p className='text-md text-gray-600 font-poppins pb-4'>{destination.destination_heading}</p>
                            
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p onClick={() => navigate('/explore-destination')}>No destinations selected.</p>
                  )}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PlanDetail;
