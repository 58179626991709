import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';
import axios from 'axios';
import bgimage from '../assets/BG.svg';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';

function TripPlans() {
  const { token } = useContext(AuthContext);
  const [filter, setFilter] = useState('confirmed');
  const [plandata, setPlanData] = useState([]);
  const [requestdetails, setRequestDetails] = useState(false);
  const [requeted,setRequested] = useState(false);
  const [created,setCreated] = useState(false);
  const navigate = useNavigate();
  const capitalizeFirstLetter = (string) => {
    if (!string) return ""; // Return empty string if the input is falsy
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  useEffect(() => {
    const fetchEnquiry = async () => {
      try {
        const response = await axios.post(
          'https://admin.tourismooze.com/api/public/enquiry_list',
          {},
          {
            headers: {
              'Authorization': `Bearer ${token}`,  // Passing token correctly
              'Content-Type': 'application/json',  // Correct Content-Type
            },
          }
        );

        const data = response.data;

        if (data.status && Array.isArray(data.data.enquiry)) {
          setPlanData(data.data.enquiry);
          console.log('Enquiries fetched successfully:', data.data.enquiry);
        } else {
          console.error('Unexpected response format:', data);
        }

        console.log('Full response data:', data);
      } catch (error) {
        console.error('Error fetching enquiry:', error.response ? error.response.data : error.message);
      }
    };

    if (token) {  // Ensure token exists before making the API call
      fetchEnquiry();
    } else {
      console.warn('Token is missing or undefined.');
    }
  }, [token]);  // Make sure token is updated correctly in dependency array

  const filteredPlans = plandata.filter((plan) => {
    if (filter === 'confirmed') return plan.status === 'requested'; // Corrected condition
    if (filter === 'inprogress') return plan.status === 'created';  // Corrected condition
    return false;
  });

  return (
    <div className='h-screen font-poppins items-center justify-center' style={{ backgroundImage: `url(${bgimage})`, backgroundSize: 'cover' }}>

      {!requestdetails ? (
        <div className='w-full md:w-[80%] mx-auto'>

          <div className='h-12'></div>
          <h1 className='text-center font-poppins font-[900] text-[30px]'>My Trip Plans</h1>
          <div className='flex w-full md:w-[80%] mx-auto justify-between my-4'>
            <div
              onClick={() => setFilter('confirmed')}
              className={`w-full cursor-pointer p-3 text-center rounded-l-full font-poppins border-r-[#11A59B] border-r[1px] ${filter === 'confirmed' ? 'bg-[#009187] text-white' : 'bg-[#AEF1EC] text-[#11A59B]'}`}
            >
              Requested
            </div>
            <div
              onClick={() => setFilter('inprogress')}
              className={`w-full cursor-pointer p-3 text-center rounded-r-full font-poppins border-r-[#11A59B] border-r[1px] ${filter === 'inprogress' ? 'bg-[#009187] text-white' : 'bg-[#AEF1EC] text-[#11A59B]'}`}
            >
              Package Created
            </div>
          </div>

          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 justify-between gap-2'>
            {filteredPlans.length > 0 ? (
              filteredPlans.map((popular, index) => (
                <div key={index} className="bg-white p-5 rounded-md shadow-md w-full border-[1px] border-[#0BD1C4]">
                  <p className="font-poppins text-[#006464] text-[20px] font-semibold py-2">{popular.enquiryid}</p>
                  <p className='text-[#202020] font-bold pb-2'>Status: {capitalizeFirstLetter (popular.status)}</p>
                  <div className=' p-2 rounded-md py-4' style={{ backgroundColor: popular.status === 'requested' ? '#FFECA7' : '#AEF1EC' }}>
                  <p className='font-poppins text-darktext pb-2 font-regular'>Trip Date: {popular.from_date}</p>
                    <p className='font-poppins text-darktext pb-2 font-regular'>Trip Duration: {popular.duration}</p>
                    <p className='font-poppins text-darktext pb-2 font-regular'>No of Destination: {popular.selected_destinations_count}</p>
                    <p className='font-poppins text-darktext pb-2 font-regular'>No of Travellers: {popular.adults} Adults & {popular.children} Childrens </p>
                    <p className='text-[#587893] font-poppins'>{popular.package_description}</p>
                  </div>
                  <button onClick={popular.status == 'created' ? ()=>navigate('/package-details',{state:{popular,isFromPlan:true}}) : () => navigate('/plan-detail', { state: popular })} className='font-poppins bg-[#1E75BB] flex p-2 px-5 rounded-full text-white mx-auto text-center mt-8 mb-2'>
                    VIEW DETAILS
                  </button>
                  
                  {/* <button onClick={() => navigate('/plan-detail', { state: plan, isFromTripPlan: true })} className='font-poppins bg-[#1E75BB] flex p-2 px-5 rounded-full text-white mx-auto text-center mt-8 mb-2'>
                    VIEW DETAILS
                  </button> */}
                </div>
              ))
            ) : (
              <p className="text-center">No bookings available.</p>
            )}
          </div>
        </div>
      ) : (
        <div className='w-full md:w-[80%] mx-auto'>
          <div className='bg-white rounded-xl border-[1px] border-gray-200 p-3'>
            {filteredPlans.map((plan, index) => (
              <p key={index}>Request ID : {plan.id}</p>
            ))}
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
}

export default TripPlans;
