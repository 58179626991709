import { Link } from "react-router-dom";
import React from "react";
import mobb from "../assets/mobb.jpg";
import web from "../assets/web.jpg";

const Banner = () => {
  return (
    <div className="relative h-2/6 lg:-mt-28 -mt-30 rounded-b-[35px] overflow-hidden">
      {/* Web Image */}
      <img src={web} alt="Banner" className="hidden md:block w-full h-[90vh] object-cover" />
      {/* Mobile Image */}
      <img src={mobb} alt="Banner" className="block md:hidden w-full h-[75vh]  object-cover rounded-b-[25px]" />
      <div className="absolute inset-0 bg-gradient-to-t from-black/30 via-transparent to-transparent bg-opacity-30" />
      {/* Black Overlay */}
      {/* <div className="absolute inset-0 bg-black opacity-30"></div> */}
      {/* Centered Text */}
      <div className="absolute top-16 inset-0 flex flex-col items-center justify-center gap-12 text-white px-2">
        <div className="flex flex-col gap-4 items-center">
          <h1 className="text-5xl md:text-[80px] font-black tracking-tighter">Let's go Places</h1>
          <span className="text-lg md:text-2xl tracking-widest text-[#87FFF7] text-center font-light mt-2">
            Explore places with Tourismooze.
          </span>
        </div>
        {/* spacer div */}
        {/* <div className="h-5 lg:h-16" /> */}
        {/* end spacer div */}
        <div className="flex  gap-5 lg:gap-8">
          <Link to={"/packages"}>
            <button className="bg-primary px-4 lg:px-6 py-2 text-base lg:text-lg rounded-full">PICK A PACKAGE</button>
          </Link>
          <Link to={"/plan-your-trip"}>
            <button className="bg-secondary px-4 lg:px-6 py-2 text-base lg:text-lg rounded-full">PLAN YOUR TRIP</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Banner;
