import React, { useState, useEffect } from "react";
import CurrencySelector from "./CurrencySelector";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import { currencies } from "../data/currencies";
import menui from "../assets/menui.png";
import Banner from "./Banner";
import ImageGrid from "./ImageGrid";
import BannerWithCards from "./BannerWithCards";
import Popup from "./Popup";
import ProfileDrawer from "./ProfileDrawer";
import bgimage from '../assets/BG.svg';


function Home({ onLoginClick }) {
  const defaultCurrency = currencies.find(
    (currency) => currency.value === "INR"
  );
  const [selectedCurrency, setSelectedCurrency] = useState(defaultCurrency);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [profiledrawer, setProfileDrawer] = useState(false);

  const handleCurrencyChange = (newCurrency) => {
    setSelectedCurrency(newCurrency);
  };

  useEffect(() => {
    console.log("Selected currency:", selectedCurrency);
  }, [selectedCurrency]); // Log the selected currency whenever it changes

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  return (
    <div style={{ backgroundImage: `url(${bgimage})`, backgroundSize: '50%', backgroundRepeat:'repeat' }} className="">
      
      <Banner />

      {/* explore section */}
      <div className="container mx-auto flex flex-col gap-6 my-12">
        <h1 className="text-2xl font-poppins text-primary font-semibold tracking-wide text-center lg:text-left">
          Explore
        </h1>
        <div>
          <ImageGrid />
        </div>
      </div>

      <BannerWithCards />
      {/* end explore section */}
      
      {isPopupVisible && <Popup onClose={handleClosePopup} />}
    </div>
  );
}

export default Home;
