import React, { useContext, useState, useEffect, useRef } from 'react';
import Header from './Header';
import Popup from './Popup';
import pkgdetailsimg from '../assets/houseboat-alappuzha-backwaters-kerala.jpg';
import calender from '../assets/Calendar.png';
import hotel from '../assets/Hotel.png';
import locationn from '../assets/Location.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import locationico from '../assets/locationico.png';
import nightico from '../assets/nightico.png';
import Footer from './Footer';
import compico from '../assets/compico.png';
import bg from '../assets/BG.png';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import Calender from '../assets/Calendar.svg';
import bed from '../assets/bed.svg';
import sedan from '../assets/sedan.svg';
import hashback from '../assets/hashback.svg';
import suv from '../assets/suv.svg';
import traveller from '../assets/traveller.svg';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';



const baseUrl = 'https://admin.tourismooze.com/storage/'; // Base URL for images
const MySwal = withReactContent(Swal);
const Tokenstatus = localStorage.getItem('token');
// Function to render star rating based on a numeric value
const renderStars = (rating) => {
  const fullStar = '★'; // Unicode for a filled star
  const emptyStar = '☆'; // Unicode for an empty star
  const starCount = 5; // Total number of stars

  // Ensure the rating is an integer between 1 and 5
  const roundedRating = Math.max(1, Math.min(starCount, Math.round(rating)));
  
  return (
    <div className="text-yellow-500 text-[15px]">
      {Array.from({ length: starCount }, (_, index) =>
        index < roundedRating ? fullStar : ''//if you want to display empty stars also just add here 'emptyStar'
      )}
    </div>
  );
};

function EnquiryPackageDetails({onLoginClick,onLogoutClick}) {
  const { token } = useContext(AuthContext);
  const { currency } = useContext(AuthContext);
  const location = useLocation();
  const plan = location.state;
  const navigate = useNavigate();
  const [numberofDays, setNumberOfDays] = useState([]);
  const [packageSlider, setPackageSlider] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedAge, setSelectedAge] = useState('');
  const [selectedadultcount, setSelectedAdultCount] = useState(1);
  const [childrencount, setChildrenCount] = useState(0);
  const [roomcount, setRoomCount] = useState(1);
  const [bgimage, setBgImage] = useState(bg);
  const [travelOption, setTravelOption] = useState('');
  const [staydata, setStayData] = useState([]);
  const [vehicleInfo,setVehicleInfo] = useState([]);
  const [highlightedActivities,setHighlightedActivities] = useState([]);
  const [content,setContent]=useState(true);
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Return the formatted date
  });
  
 


  const [endDate, setEndDate] = useState(new Date());
  const [selectedRoom, setSelectedRoom] = useState();
  const [total, setTotal] = useState();
  const [selectedhotel,setSelectedHotel] =useState([]);
  const [newhotels,setNewHotels] = useState([])
  const Login = useSelector((state) => state.Login);
  const [confirm,setConfirm] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const [loader,setLoader]=useState(false);
  const [roomchange,setRoomChange] = useState(false)
  const [personPrice,setPersonPrice]=useState()



useEffect(()=>{
if(plan ==true){
  setConfirm(true);
  setLoader(false);
}
},[])


  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);



  const handleLoginClick = () => {
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  const sliderRef = useRef(null);

  const images = [
    pkgdetailsimg,
    pkgdetailsimg,
    pkgdetailsimg
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    appendDots: (dots, currentSlide) => (
      <div className='grid grid-cols-1 lg:grid-cols-2 items-center gap-y-0 lg:gap-y-2 px-0 lg:px-12' style={{ bottom: '20px', paddingLeft: '20px', paddingRight: '20px' }}>
        <ul className="flex justify-center xl:justify-start space-x-1 lg:m-0">{dots}</ul>
        <div className='text-white flex justify-center xl:justify-end items-center space-x-2 xl:-mt-8 m-2'>
          <div className='backdrop-blur-sm bg-white/30 flex justify-center lg:justify-end items-center space-x-2 px-5 lg:py-2 py-1 rounded-full'>
            <button disabled={plan == true} onClick={() => sliderRef.current.slickPrev()} className='bg-transparent border-none flex items-center space-x-1 text-sm'>
              <span>&#x2190;</span> <span>PREV</span>
            </button>
            <span className="text-white">|</span>
            <button disabled={plan == true} onClick={() => sliderRef.current.slickNext()} className='bg-transparent border-none flex items-center space-x-1 text-sm'>
              <span>NEXT</span> <span>&#x2192;</span>
            </button>
          </div>
        </div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };









  // Fetch plan package details
  useEffect(() => {
    const fetchplanDataDetails = async () => {
      try {
        const response = await axios.post(`https://admin.tourismooze.com/api/public/enquiry_package/${plan.id}`, null, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        });
        const planpackagesdetail = response.data.data.package_detail;
        const daydetails = planpackagesdetail.days;
        // const destinationId = daydetails.map((destid)=>(
        //   destid.destination_id
        // ));
        // console.log('destinationId',destinationId)
        const packageslider = planpackagesdetail.images;
        setNumberOfDays(daydetails);
        setPackageSlider(packageslider);
      } catch (error) {
        console.error('Error fetching plan packages', error);
      }
    };
    fetchplanDataDetails();
  }, [plan.id, token]);

  useEffect(() => {
   

    fetchAdditionalData();
  }, [plan.id,token]);


// useEffect(()=>{
//   fetchRealPrice()
// },[])



const fetchUpdates = async () => {
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
  const payload = {
    package_id: `${plan.id}`,
    adults: selectedadultcount,
    rooms: roomcount,
    TripPlans_date: startDate,
    currency: currency,
  };

  try {
    const response = await axios.post(
      `https://admin.tourismooze.com/api/public/package-detail/${plan.id}`,
      payload, // Send the payload directly
      { headers } // Pass headers separately
    );

    const VehicleInfo = response.data.data.package_detail.vehicles;
    console.log(VehicleInfo, 'car-details');
    setVehicleInfo(VehicleInfo);
  } catch (error) {
    console.error('Error fetching additional data:', error); // Log the error for debugging
  }
};





  const increaseAdult = () => {
    setSelectedAdultCount(prev => {
      const newCount = prev + 1;
      // Pass the updated adult count and the current child count
      fetchRealPrice(newCount, childrencount, roomcount, startDate, endDate); 
      

      return newCount;
    });
  };
  
  const decreaseAdult = () => {
    setSelectedAdultCount(prev => {
      const newCount = Math.max(1, prev - 1);
      // Pass the updated adult count and the current child count
      fetchRealPrice(newCount, childrencount, roomcount, startDate, endDate);
      
      return newCount;
    });
  };
  
  const increaseChildrenCount = () => {
    setChildrenCount(prev => {
      const newCount = prev + 1;
      // Pass the current adult count and the updated child count
      fetchRealPrice(selectedadultcount, newCount, roomcount, startDate, endDate);
      

      return newCount;
    });
  };
  
  const decreaseChildrenCount = () => {
    setChildrenCount(prev => {
      const newCount = Math.max(0, prev - 1);
      // Pass the current adult count and the updated child count
      fetchRealPrice(selectedadultcount, newCount, roomcount, startDate, endDate);
      
      return newCount;
    });
  };
  


  const increaseRoomCount = () => {
    setRoomCount(prev => {
      const newCount = prev + 1;
      // Pass the current adult count and the updated room count
      fetchRealPrice(selectedadultcount, childrencount, newCount, startDate, endDate);
     
 
      return newCount;
    });
  };
  
  const decreaseRoomCount = () => {
    setRoomCount(prev => {
      const newCount = Math.max(1, prev - 1);
      // Pass the current adult count and the updated room count
      fetchRealPrice(selectedadultcount, childrencount, newCount, startDate, endDate); 
      

      return newCount;
    });
  };

  const handleStartDateChange = (date) => {
    const startDateString = date.toISOString().split('T')[0];
    setStartDate(startDateString);
    // Trigger the API call with updated start date
    fetchRealPrice(selectedadultcount, childrencount, roomcount, date, endDate);
   

  };
  
  const handleEndDateChange = (date) => {
    setEndDate(date);
    // Trigger the API call with updated end date
    fetchRealPrice(selectedadultcount, childrencount, roomcount, startDate, date);
  };
  


const handleChange = (event) => {
    const age = event.target.value;
    setSelectedAge(age);
    console.log(age);
  };










  const fetchAdditionalData = async () => {
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };

    const payload = {
        package_id: plan.id,
        TripPlans_date: startDate,
        adults: selectedadultcount,
        currency: currency,
        rooms: roomcount,
    };

    try {
        const response = await axios.post(
            `https://admin.tourismooze.com/api/public/order_update`,
            payload, // Send the payload directly
            { headers } // Pass headers separately
        );

        const StayInfo = response.data.data.rooms;
        const VehicleInfo = response.data.data.vehicles;
        const Activities = response.data.data.highlighted_activities;
        const totalprice = response.data.data.total;
        setTotal(totalprice)
        // setPersonPrice(response.data.data.price_per_person)
       
        console.log(VehicleInfo, 'car-details');
        setVehicleInfo(VehicleInfo);
        console.log('stayInfo', StayInfo);
        setStayData(StayInfo);
        setHighlightedActivities(Activities);

    } catch (error) {
        console.error('Error fetching additional data:', error); // Log the error for debugging
    }
};



const fetchLiveAdditionalData = async (selectedadultcount, childrencount, roomcount, startDate, endDate) => {
  const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
  };
  const payload = {
      package_id: plan.id,
      TripPlans_date: startDate,
      adults: selectedadultcount,
      currency: currency,
      rooms: roomcount
  };

  try {
    setLoader(true)
      const response = await axios.post(
          `https://admin.tourismooze.com/api/public/order_update`,
          payload, // Send the payload directly
          { headers } // Pass headers separately
      );
      setLoader(false);

      const StayInfo = response.data.data.rooms;
      const VehicleInfo = response.data.data.vehicles;
      const Activities = response.data.data.highlighted_activities;
      const totalPrice = response.data.data.total;
      setTotal(totalPrice);
      console.log("totallll",total)
      console.log(VehicleInfo, 'car-details');
      setVehicleInfo(VehicleInfo);
      console.log('stayInfo', StayInfo);
      setStayData(StayInfo);
      setHighlightedActivities(Activities);

  } catch (error) {
      console.error('Error fetching additional data:', error); // Log the error for debugging
  }
};

const fetchLiveStayData = async () => {
  const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
  };
  const payload = {
      package_id: plan.id,
      TripPlans_date: startDate,
      adults: selectedadultcount,
      currency: currency,
      rooms: roomcount
  };

  try {
      const response = await axios.post(
          `https://admin.tourismooze.com/api/public/order_update`,
          payload, // Send the payload directly
          { headers } // Pass headers separately
      );

      const StayInfo = response.data.data.rooms;
      const VehicleInfo = response.data.data.vehicles;
      const Activities = response.data.data.highlighted_activities;
      const totalPrice = response.data.data.total;

      console.log("totallll",total)
      console.log(VehicleInfo, 'car-details');
      setVehicleInfo(VehicleInfo);
      console.log('stayInfo', StayInfo);
      setStayData(StayInfo);
      setHighlightedActivities(Activities);

  } catch (error) {
      console.error('Error fetching additional data:', error); // Log the error for debugging
  }
};

















// Changing room details
const editRoom = async (room_id, hotel_id, day_number,destination_id) => {
  togglePopup()
 console.log('rooomId',room_id)
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
  
  const payload = {
    "adult" : selectedadultcount,
    "child" : childrencount,
    "room_id" : room_id,
    "day_number" :day_number,
    "package_id" : `${plan.id}`
  };

  try {
    const response = await axios.post(
      `https://admin.tourismooze.com/api/public/package/hotels/${destination_id}`,
      payload, // Payload goes here
      { headers } // Headers should be passed as the third argument
    );
    
    const selectedHotel = response.data.data.selected_room;
    setSelectedHotel(selectedHotel);
    setRoomChange(true);
    const newHotels = response.data.data.rooms;
    setNewHotels(newHotels);
    // fetchAdditionalData()
    console.log('New room data:', selectedRoom);
  } catch (error) {
    console.error('Error fetching new room data:', error); // Log the error for debugging
  }
};
// end changing room details

// apply room change function
const ApplyRoomChange = async (room_id, hotel_id, day_number, destination_id, hotel_name) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
  
  const payload = {
    hotel_id:hotel_id,
    adults: selectedadultcount,
    child: childrencount,
    room_id: room_id,
    day_number: day_number,
    package_id: `${plan.id}`,
    rooms:roomcount,
    TripPlans_date:startDate,
    currency:currency,
  };

  // Log the payload for debugging
  console.log('Payload being sent:', payload);

  try {
    const response = await axios.post(`https://admin.tourismooze.com/api/public/apply_room/${room_id}`, payload, { headers });
    
    // Log the response for debugging
    console.log('Response received:', response);

    if (response.status === 200) {
      setSelectedRoom(room_id); // Set the selected room ID
      MySwal.fire({
        title: <p className='text-sm font-poppins'>{`You've selected ${hotel_name}`}</p>,
        footer: 'Tourismooze',
      });
      togglePopup();
      fetchLiveStayData();
      fetchAdditionalData(selectedadultcount,roomcount)
    }
  } catch (error) {
    // Log the error for debugging
    console.error('Error during room change application:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
    }
  }
};

// end apply room change function



// edit vehicle section
const EditVehicle = async (id,vehicle_type) => {

  if(plan == true){
    MySwal.fire({
      title: <p className='font-poppins text-red-800 text-xl'>{`this feature is disabled`}</p>,
      footer: 'Tourismooze',
    });
  } else{

  

  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
  
  const payload = {
    vehicle_id: id,
    package_id: `${plan.id}`,
      adults:selectedadultcount,
      rooms:roomcount,
      TripPlans_date:startDate,
      currency:currency
  };

    const response = await axios.post(`https://admin.tourismooze.com/api/public/package/change_vehicle`, payload, { headers }).then(
      MySwal.fire({
        title: <p className='text-sm font-poppins'>{`You've selected ${vehicle_type}`}</p>,
        footer: 'Tourismooze',
      }),
      fetchUpdates(),
      fetchLiveStayData(),
      fetchAdditionalData(selectedadultcount,roomcount)
    )
    const VehicleInfo = response.data;
  }
};

// end edit vehicle section





// Price live update
const fetchRealPrice = async (newAdultCount, newChildrenCount,rooms, newRoomCount,)=>{ 
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
  
  const payload = {
   "package_id" : `${plan.id}`,
    "TripPlans_date" : startDate,
    "total" : `${plan.price_per_person}`,
    "adults" : newAdultCount,
    "children" : newChildrenCount,
    "currency" : currency,
    "rooms": rooms,

  };
  try{
const response = await axios.post(`https://admin.tourismooze.com/api/public/confirm_order`,payload, { headers });
    console.log('TripPlans status',response.status);
    const latestprice = response.data.data;
    if(response.status === 200){
      // PaymentSuccess();
      fetchLiveAdditionalData(newAdultCount, newChildrenCount,rooms, newRoomCount, startDate, endDate);
      
    }
  }catch(error){

  }
}
// end Price live update



// TripPlans function
const HandleTripPlans = async ()=>{ 
  setLoader(true);
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
  
  const payload = {
   "package_id" : `${plan.id}`,
    "TripPlans_date" : startDate,
    "checkout_date" : endDate,
    "total" : `${plan.price_per_person}`,
    "adults" : selectedadultcount,
    "children" : childrencount,
    "currency" : currency,
    "rooms":roomcount,

  };
  try{
const response = await axios.post(`https://admin.tourismooze.com/api/public/order_update`,payload, { headers });
    console.log('TripPlans status',response.status);
    const Details = response.data.data;
    if(response.status === 200){
      // PaymentSuccess();
      setLoader(false);
      navigate('/payment-page', { state: { payload, Details } });
    }
  }catch(error){

  }
}
// end TripPlans function



const HandleTripPlansOne = ()=>{
  setConfirm(true);
  // onLoginClickk();
  setIsPopupVisible(true)
}
const HandleTripPlansTwo =  ()=>{
  setConfirm(true);
  
}




 
  const handleTripPlansOptionChange = (event) => {
    const selectedValue = event.target.value;
    setTravelOption(selectedValue);
    console.log('Selected option:', selectedValue);
  };


 const handleCloseClick =()=>{
  setIsPopupVisible(false)
 }

  


  return (
    <div>
       
        {isPopupVisible && <Popup onCloseClick={handleCloseClick} />}


{/* body section */}
        <div>


<div className='py-4'>
  <h1 className='text-center text-[#006464] font-poppins text-3xl font-bold pt-10'>{plan.name}{plan.package_name}</h1>
  </div>

{!plan && <div className='flex justify-center py-5 font-poppins text-black '>

  <div className='flex flex-wrap md:flex-nowrap items-center gap-2 m-2'>
    <div><img src={calender} alt='calender' /></div>
    <div>{plan.number_of_nights} nights</div>
  </div>

  <div className='flex flex-wrap md:flex-nowrap items-center gap-2 m-2'>
    <div><img src={locationn} alt='hotel' /></div>
    <div>{plan.Pickup_location}</div>
  </div>

  <div className='flex flex-wrap md:flex-nowrap items-center gap-2 m-2'>
    <div><img src={hotel} alt='location' /></div>
    <div>{plan.number_of_nights} Resorts</div>
  </div>

</div>}
















<div>
  <div className="w-[90%] mx-auto ">
    <Slider {...settings} ref={sliderRef}>
      {packageSlider.map((image, index) => (
        <div key={index} className='md:h-96 h-auto relative rounded-xl'>
          <img src={baseUrl+image.image} alt={`Slide ${index + 1}`} className="w-full rounded-xl bg-center object-contain" />
          {/* <div className="absolute inset-0 bg-black opacity-50"></div> */}
        </div>
      ))}
    </Slider>
  </div>
</div>



<div className="h-auto w-full min-w-full pb-10 bg-cover bg-center xl:-mt-56 lg:-mt-32 md:-mt-18 -mt-16 rounded-t-3xl" style={{ backgroundImage: `url(${bgimage})` }}>





<div className='mx-auto flex flex-wrap lg:flex-nowrap w-[100%] md:w-[80%] lg:w-[90%] justify-center md:pt-40 lg:pt-72 pt-32 overflow-x-clip'>
  
{/* timeline section */}

      <div className="mx-auto px-4 py-8 max-w-[100%] lg:max-w-[80%] min-w-[70%]">
  <p className="font-bold text-lg mb-2 text-white font-poppins">Day By Day Plan </p>
  


<div>
{numberofDays.map((day, index) => (
    <div key={index} className="relative mb-1 flex items-start">
      {/* Dotted Line */}
      <div className="absolute h-full border-l-4 border-dotted border-white left-6"></div>
      {/* end Dotted Line */}

      {/* Big Dot */}
      <div className="w-10 flex-none">
        <div className="bg-[#006464] rounded-full w-5 h-5 border-4 border-white relative left-4 mt-4"></div>
      </div>
      {/* end Big Dot */}

      {/* content section */}
       <div className="ml-2 flex-1">
        <p className='text-white font-poppins lg:text-2xl font-bold text-xl mb-4 mt-3'>Day {day.day_number} - {day.destination_name}</p>

        {content && <div className='grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 w-full'>
          {day.activities.map((activity, activityIndex) => (
            <div key={activityIndex} className='flex'>
              {activity.description !== null ? 
                <div className='bg-white flex flex-col justify-top w-full rounded-lg p-2 shadow-md min-h-60 max-h-96'>
                  <div className='flex justify-between mb-5 gap-5'>
                    <p className='text-black font-semibold text-[12px] font-poppins'>{activity.name}</p>
                    <img src={baseUrl + activity.image} alt={activity.name} className='w-8' />
                  </div>
                  <div>
                    <p className='text-black/70 font-normal text-[12px] font-poppins'>{activity.description}</p>
                  </div>
                </div> 
              : 
                <div className='relative h-full w-full shadow-md'>
                  <img src={baseUrl + activity.image} alt={activity.name} className='h-full object-cover w-full rounded-lg' />
                  <div className='absolute inset-0 bg-black bg-opacity-30 rounded-lg'></div>
                  <div className='absolute bottom-0 left-0 p-2'>
                    <p className="text-white font-semibold font-poppins text-[12px] w-full">{activity.name}</p>
                  </div>
                </div>
              }
            </div>
          ))}
        </div>}
      </div> 
      {/* end content section */}
    </div> 
  ))}

<buton className="bg-[white] px-4 py-1 text-[#11A59B] rounded-full justify-center flex text-center mx-auto w-48 cursor-pointer" onClick={!content ? ()=>{setContent(true)} : ()=>{setContent(false)}}>{content? 'COLLAPSE PLAN':' EXPAND PLAN'}</buton>
</div>
 







  {/* whats include section */}
  <div className='h-28'></div>
  <div className='w-full min-w-full bg-white rounded-2xl m-5 p-8 mx-auto py-12'>
    <p className='font-poppins font-bold text-left'>What’s include in this package</p>
    <p className='font-poppins text-left text-[#587893]'>Get full details of stay your transportation, flight or trains, other activities here</p>
   { !confirm && <div className='bg-[#F5F5F5] p-10 mt-8 rounded-lg my-2'>
      <p className='font-poppins text-center text-[#1E75BB]'>Please complete your TripPlans, then the system will confirm and update information for you</p>
    </div>}




{token && confirm ? 
                  <div>
                    {/* stay section start */}
                    <p className="font-poppins font-bold text-left p-2">
                      Your Stay
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
                      {staydata.map((stay, index) => (
                        <div
                          key={index}
                          className="border p-2 rounded-lg  bg-lightgray hover:shadow-lg  hover:border-solid-mediumgray cursor-pointer"
                        >
                          <div className="relative mb-5">
                            <img
                              src={baseUrl + stay.room_image}
                              className="rounded-lg w-full"
                              alt="Room"
                            />
                            <div className="absolute bottom-0 left-0 w-full flex justify-left gap-3 -mb-3 mx-4">
                           
                              <p className="bg-primary text-xs font-normal text-white py-1 px-4 rounded-xl">
                              Day #{stay.day_number}
                              </p>
                              <p className="bg-primary text-xs font-normal text-white py-1 px-4 rounded-xl">
                              Room #{stay.room_count}
                              </p>
                            </div>
                            <button
                              onClick={(e) => {
                                e.stopPropagation(); // Stop the event from bubbling up
                                editRoom(
                                  stay.room_id,
                                  stay.hotel_id,
                                  stay.day_number,
                                  stay.destination_id
                                );
                              }}
                              className="absolute top-0 right-0 p-1 m-2 bg-white rounded-md  px-2 text-dotblue border-solid border-2 border-dotblue text-sm tracking-wider"
                            >
                              Change
                            </button>
                          </div>

                          <p className="font-medium text-left">
                            {stay.hotel_name}
                          </p>

                          <div className="flex gap-2 text-[14px] text-[#587893] items-center">
                            {renderStars(stay.hotel_type)} {stay.hotel_type}{" "}
                            Star
                          </div>

                          <div className="flex flex-col gap-2 mt-6">
                            <div className="flex gap-3 items-center">
                              <img src={Calender} alt="calendar" width={20} />
                              <p className="text-sm text-tertiary font-medium">
                                
                                {stay.TripPlans_date}
                                
                              </p>
                            </div>

                            <div className="flex gap-3 items-center">
                              <img src={bed} alt="bed" width={20} />
                              <p className="text-sm text-tertiary font-medium">
                                {stay.room_name} - {stay.adult_count} Adult,{" "}
                                {stay.child_count} Child
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {/* stay section end */}

{/* vehicle section */}
<div className='flex justify-between items-center'>
<h1 className='text-[#11A59B] font-bold my-6 font-poppins'>Choose your Transportation</h1>
</div>

<div className='my-4 flex items-center gap-2'>
{vehicleInfo.map((car,index)=>(
  <div key={index}>
      { car.vehicle_type == 'Sedan' ? <div onClick={()=> car.selected == 0 ? EditVehicle(car.id,car.vehicle_type):''} className={`border-[3px] ${car.selected === 0 ? 'border-[#E4FFFF]' : 'border-[#11A59B]'}  rounded-md`}><img src={sedan} /><p className={`p-2 text-center ${car.selected === 0 ? 'bg-[#E4FFFF]' :'bg-[#11A59B] text-white'}`}>{car.vehicle_type}</p></div>:'' }



      { car.vehicle_type == 'Hatchback' ? <div onClick={()=> car.selected == 0 ? EditVehicle(car.id,car.vehicle_type):''} className={`border-[3px] ${car.selected === 0 ? 'border-[#E4FFFF]' : 'border-[#11A59B]'}  rounded-md`}><img src={hashback} /><p className={`p-2 text-center ${car.selected === 0 ? 'bg-[#E4FFFF]' :'bg-[#11A59B] text-white'}`}>{car.vehicle_type}</p></div>:'' }



      { car.vehicle_type == 'SUV' ? <div onClick={()=> car.selected == 0 ? EditVehicle(car.id,car.vehicle_type):''} className={`border-[3px] ${car.selected === 0 ? 'border-[#E4FFFF]' : 'border-[#11A59B]'}  rounded-md`}><img src={suv} /><p className={`p-2 text-center ${car.selected === 0 ? 'bg-[#E4FFFF]' :'bg-[#11A59B] text-white'}`}>{car.vehicle_type}</p></div>:'' }



      { car.vehicle_type == 'Traveller' ? <div onClick={()=> car.selected == 0 ? EditVehicle(car.id,car.vehicle_type):''} className={`border-[3px] ${car.selected === 0 ? 'border-[#E4FFFF]' : 'border-[#11A59B]'}  rounded-md`}><img src={traveller} /><p className={`p-2 text-center ${car.selected === 0 ? 'bg-[#E4FFFF]' :'bg-[#11A59B] text-white'}`}>{car.vehicle_type}</p></div>:'' }
  </div>
))}
</div>
{/* end vehicle section */}

{/* highlighted activities section */}
<div className='flex justify-between items-center'>
<h1 className='font-bold my-6 font-poppins'>Activities</h1>

</div>

<div className='flex flex-wrap gap-6'>
  {highlightedActivities.map((activity,index)=>(
    <div key={index} className='border-[#0BD1C4] border-[1px] p-4 rounded-md bg-[#F5F5F5] w-full sm:w-56'>
      <div className='justify-between items-center flex flex-nowrap'>
      <p className=' font-poppins font-semibold'>{activity.name}</p>
      <img src={baseUrl+activity.image} className='w-8' />
      </div>
      <p className='font-poppins text-[12px] text-justify mt-4'>{activity.description}</p>
    </div>
  ))}
</div>
{/* end highlighted activities section */}
</div>:''}







  </div>
  {/* end whats include section */}
</div>
{/* end timeline section */}










{/* number of travelers section */}
<div className="flex justify-center items-start px-5 order-first lg:order-last w-full">
<div className="bg-white px-4 py-4 rounded-lg shadow-lg w-max h-max sticky top-1">
          <p className='text-[#11A59B] font-bold text-xl font-poppins pb-4'>Choose No of Travellers</p>

          <div className='bg-[#11A59B] p-4 rounded-t-2xl py-5'>
  <div className='flex justify-between items-center mb-12'>
    <div className='text-white font-poppins'>Adults</div>
    <div className='grid grid-cols-3 gap-2'>
      <div>
        <button disabled={plan == true} onClick={decreaseAdult} className='border-[1px] border-white px-2 font-poppins text-white rounded-xl'>-</button>
      </div>
      <div className='text-center text-white font-poppins'>{selectedadultcount}</div>
      <div>
        <button disabled={plan == true} onClick={increaseAdult} className='border-[1px] border-white px-2 font-poppins text-white rounded-xl'>+</button>
      </div>
    </div>
  </div>

  <div className='flex justify-between items-center gap-5 mb-12'>
    <div className='text-white font-poppins'>Children (0 to 14 years)</div>
    <div className='grid grid-cols-3 gap-2'>
      <div>
        <button disabled={plan == true} onClick={decreaseChildrenCount} className='border-[1px] border-white px-2 font-poppins text-white rounded-xl'>-</button>
      </div>
      <div className='text-center text-white font-poppins'>{childrencount}</div>
      <div>
        <button disabled={plan == true} onClick={increaseChildrenCount} className='border-[1px] border-white px-2 font-poppins text-white rounded-xl'>+</button>
      </div>
    </div>
  </div>

  

  {/* <div className='flex justify-between items-center mb-12'>
    <div className='text-white font-poppins'>Age - Child 1</div>
    <div className='grid grid-cols-1'>
      <select className='border rounded p-2 w-full font-thin text-blue-500 font-poppins' onChange={handleChange} value={selectedAge}>
        <option className='text-center' value="" disabled>Age</option>
        {[...Array(15).keys()].map(i => {
          const age = i;
          return (
            <option key={age} value={age}>{age}</option>
          );
        })}
      </select>
    </div>
  </div> */}

  <div className='flex justify-between items-center mb-12'>
    <div className='text-white font-poppins'>Rooms</div>
    <div className='grid grid-cols-3 gap-2'>
      <div>
        <button disabled={plan == true} onClick={decreaseRoomCount} className='border-[1px] border-white px-2 font-poppins text-white rounded-xl'>-</button>
      </div>
      <div className='text-center text-white font-poppins'>{roomcount}</div>
      <div>
        <button disabled={plan == true} onClick={increaseRoomCount} className='border-[1px] border-white px-2 font-poppins text-white rounded-xl'>+</button>
      </div>
    </div>
  </div>

  {/* Date picker section */}

 <div className='flex flex-wrap md:flex-nowrap justify-between items-center my-2 gap-6'>
  <p className='text-white font-poppins'>start Date</p>
  <DatePicker   minDate={new Date()}  className='text-[14px] text-gray-400 p-1 rounded-md' selected={startDate} onChange={(date) => handleStartDateChange(date)} />

 </div>

 {/* <div className='flex justify-between items-center my-2'>
  <p className='text-white font-poppins'>end Date</p>
  <DatePicker   minDate={new Date()} className='text-[14px] text-gray-400 p-1 rounded-md' selected={endDate} onChange={(date) => handleEndDateChange(date)}  />

 </div> */}
  {/* end date picker section */}
</div>




{/* selected info section */}
<div className='bg-[#E4FFFF] py-8 rounded-b-2xl'>
<div className='grid grid-cols-3 sm:grid-cols-2 xl:grid-cols-3 justify-start w-[100%] px-4'>
<div className='text-[#1E75BB] font-bold font-poppins'>Rooms -  {roomcount}</div>
<div className='text-[#1E75BB]  font-poppins'>{selectedadultcount} Adults , </div>
<div className='text-[#1E75BB]  font-poppins'>{childrencount} child</div>


</div>
</div>

{/* end selected info section */}


{/* price section */}
<div className='bg-[#AEF1EC] my-12 font-poppins p-5 rounded-xl'>
{/* <p className='text-[#006464] font-bold'><b className='text-[#006464] text-4xl'>{personPrice}</b>/Person</p> */}
{/* {Array.isArray(total) && total.map((amount, index) => (
  <div key={index}>
   <p className='text-[#006464] font-bold text-right'>Total : <b className='text-[#006464] text-4xl'>{amount.total}</b></p>
  </div>
))} */}
   <p className='text-[#006464] font-bold text-right'>Total : {!loader ? <b className='text-[#006464] text-4xl'>₹ {total}</b>:'fetching...' }</p>
<p className='text-[#006464] py-2'>4 night, Activities & Other Amenities</p>
</div>
{/* end price section */}

{/* travel option section */}
<div>
  {/* <p className='text-center font-poppins pb-6'>
    Would you like to arrange your <br/> travel with this package ?
  </p> */}

  {/* <div className='grid lg:grid-cols-2 grid-cols-1 items-center mx-auto w-full justify-end gap-5 text-center bg-[#F5F5F5] font-poppins rounded-lg py-12 p-2'>
    <div>
      <label className='text-[#11A59B] font-semibold'>
        <input className='appearance-none h-3 w-3 border border-gray-300 rounded-full checked:bg-[#11A59B] checked:border-transparent focus:outline-none'
          type='radio'
          name='travelOption'
          value='flight'
          checked={travelOption === 'flight'}
          onChange={handleTripPlansOptionChange}
        /> BOOK WITH FLIGHT
      </label>
    </div>
    <div>
      <label className='text-[#11A59B] font-semibold'>
        <input className='appearance-none h-3 w-3 border border-gray-300 rounded-full checked:bg-[#11A59B] checked:border-transparent focus:outline-none'
          type='radio'
          name='travelOption'
          value='train'
          checked={travelOption === 'train'}
          onChange={handleTripPlansOptionChange}
        /> BOOK WITH TRAIN
      </label>
    </div>
  </div> */}

  {/* Center the button */}
  <div className='flex justify-center my-12'>
    {/* {Tokenstatus != null && confirm ? '':  <button className='bg-[#11A59B] rounded-full px-20 py-3 text-white text-center' onClick={Tokenstatus != null ? HandleTripPlansTwo : HandleTripPlans}>
      CONFIM ORDER
    </button>}
   

    {Tokenstatus != null && confirm ? <button className='bg-[#11A59B] rounded-full px-20 py-3 text-white text-center' onClick={HandleTripPlans}>{Tokenstatus == null ? 'Loading..':`CONFIRM PAYMENT`}</button>:''} */}


    {token && confirm ? '':<button disabled={plan == true} className='bg-[#11A59B] rounded-full px-20 py-3 text-white text-center' onClick={token && !confirm ? HandleTripPlansTwo:HandleTripPlansOne}>
      CONFIM ORDER
    </button>}
      {token && confirm ? <button disabled={plan == true} className='bg-[#11A59B] rounded-full px-20 py-3 text-white text-center' onClick={HandleTripPlans}>{!Tokenstatus ? 'Loading..':`CONFIRM PAYMENT`}</button> :''}



  </div>
</div>
{/* travel option section end */}





        </div>
      </div>

{/* end number of travelers section */}

{isOpen && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="relative p-2 lg:p-4 bg-white rounded-lg shadow-lg w-full md:w-[70%] overflow-y-auto">
      <p className="font-poppins pb-4 md:text-[20px] text-[16px] font-bold">Change This Stay</p>

      {selectedhotel.map((hotel, index) => (
        <div key={index} className="flex flex-col md:flex-row items-center gap-6 mb-6">
          <img className="w-full md:w-32 rounded-lg object-cover" src={baseUrl + hotel.room_image} />
          <div className="flex-1">
            <p className="font-poppins pb-1 md:text-[20px] text-[16px] font-bold">{hotel.hotel_name}</p>
            <div className="flex gap-2 text-[14px] text-[#587893] items-center">
              {renderStars(hotel.star_rating)} {hotel.hotel_type} star
            </div>

            <div className="flex gap-3 items-center my-1">
              <img src={Calender} alt="calendar" />
              <p className="text-[16px] text-[#587893] font-semibold font-poppins">
                Fri, 16 Feb - Sun, 18 Feb
              </p>
            </div>

            <div className="flex gap-3 items-center">
              <img src={bed} alt="bed" />
              <p className="text-[16px] text-[#587893] font-semibold font-poppins">
                {hotel.room_name}, {hotel.adults} Adult, {hotel.children} Child
              </p>
            </div>
          </div>
        </div>
      ))}

      {/* New hotel suggestion area */}
      <div className="my-5 bg-[#AEF1EC] py-2 rounded-xl px-2 md:px-5">
        <p className=" py-4 font-poppins text-[16px] font-bold">SELECT FROM NEARBY STAY OPTIONS</p>
        <div className="flex flex-wrap gap-6">
          {newhotels.map((newHotel, index) => (
            <div key={index} className="border-[1px] p-2 md:p-4 rounded-lg bg-[#f1f1f1] hover:bg-[#e5e5e5] cursor-pointer">
              <div className="relative mb-5">
                <img src={baseUrl + newHotel.room_image} className="rounded-lg w-full md:w-60 object-cover" alt="Room" />
                <div className="absolute bottom-0 left-0 w-full flex justify-start gap-3 -mb-3 mx-1 lg:mx-2">
                  <p className="bg-[#11A59B] text-[12px] font-normal text-white py-1 px-2 rounded-full">Hotel #{newHotel.hotel_id}</p>
                  <p className="bg-[#11A59B] text-[12px] font-normal text-white py-1 px-2 rounded-full">Room #{newHotel.room_id}</p>
                  <p
                    onClick={() => ApplyRoomChange(newHotel.room_id, newHotel.hotel_id, newHotel.day_number, newHotel.destination_id, newHotel.hotel_name)}
                    className="bg-white text-[12px] font-normal text-[#1E75BB] border-[#1E75BB] border-[1px] py-1 px-2 rounded-full">
                    Select
                  </p>
                </div>
              </div>

              <p className="font-poppins font-semibold text-left">{newHotel.hotel_name}</p>
              <div className="flex gap-2 text-[14px] text-[#587893] items-center">
                {renderStars(newHotel.hotel_type)} {newHotel.hotel_type} star
              </div>

              <div className="flex gap-3 items-center my-2">
                <img src={Calender} alt="calendar" />
                <p className="text-[16px] text-[#587893] font-semibold font-poppins">
                  Fri, 16 Feb - Sun, 18 Feb
                </p>
              </div>

              <div className="flex gap-3 items-center">
                <img src={bed} alt="bed" />
                <p className="text-[16px] text-[#587893] font-semibold font-poppins">
                  {newHotel.room_name}, {newHotel.adult_count} Adult, {newHotel.child_count} Child
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* End new hotel suggestion area */}

      <button disabled={plan == true}
        className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
        onClick={togglePopup}
      >
        &times;
      </button>
    </div>
  </div>
)}








</div>
</div>











        </div>
        {/* end body section */}


{/* footer section */}
<Footer />
{/* end body section */}






    </div>
  )
}

export default EnquiryPackageDetails